@import "./../../assets/sass/main";

.Header {
  width: 100%;
  padding-top: rem(35px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(20px);

  .row {
    align-items: center;
  }

  .MainNavigation {
    margin-left: auto;
  }
}
