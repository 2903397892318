@import "./../../assets/sass/main";

.Reservation {
  width: 100%;
  padding-top: rem(100px);

  &__container {
    display: block;
    margin: rem(50px);

    @include mobile {
      margin: rem(15px);
    }
  }

  &__head {
    width: 100%;
    padding: rem(20px 0);
    margin-bottom: rem(50px);

    display: none;

    h2 {
      display: block;
      width: 40%;
      font-size: rem(25px);
      font-weight: 700;
      color: theme-color(secondary);
    }
    p {
      display: block;
      width: 40%;
      font-size: rem(13px);
      color: theme-color(secondary);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-gap: rem(20px);
    width: 100%;
    padding-bottom: rem(50px);

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
