@import "../../assets/sass/main";

.Search {
  width: 100%;

  @include mobile {
    background-color: #ffffff;
  }

  &__Wrapper {
    margin: rem(0 60px);
    display: flex;
    box-shadow: 15px 16px 60px 0 rgba(0, 0, 0, 0.15);
    padding: rem(15px);
    width: auto;

    @include mobile {
      margin: 0;
      flex-direction: column;
    }

    button {
      margin-left: auto;
      border: none;
      outline: none;
      background-color: theme-color(primary);
      padding: rem(0 30px);
      color: white;
      text-transform: uppercase;
      font-size: rem(20px);
      height: rem(60px);
      align-self: center;

      @include mobile {
        width: 100%;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__Locations {
    display: block;
    position: relative;
    width: rem(30%);

    @include mobile {
      width: 100%;
      margin-bottom: rem(10px);
    }

    &__Label {
      display: block;
      font-size: rem(12px);
      text-transform: uppercase;
      color: #333;
    }

    &__Diff {
      display: flex;
      align-items: center;
      padding-left: rem(20px);
      font-size: rem(11px);
      line-height: rem(15px);

      input {
        display: block;
      }
    }

    input {
      display: block;
      height: rem(50px);

      &:focus {
        outline: none;
      }
    }
  }

  &__LocationInputs {
    display: flex;
    align-items: center;

    select {
      display: block;
      border-radius: unset;
      border: unset;
      height: rem(50px);
      padding: rem(0 0);
      font-size: rem(18px);
      font-weight: 700;
      appearance: unset;

      @include mobile {
        width: 100%;
      }

      &:focus {
        outline: none;
        border: none;
        box-shadow: unset;
      }
    }
  }

  &__DateTime {
    @include mobile {
      margin-bottom: rem(10px);
    }
    &__Label {
      display: block;
      font-size: rem(12px);
      text-transform: uppercase;
      color: #333;
    }

    input {
      height: rem(40px);
      width: rem(150px);
      border: none;
      font-size: rem(18px);
      font-weight: 700;
      border-left: 1px #dadada solid;
      padding-left: rem(15px);
      margin-left: rem(15px);

      @include mobile {
        width: 100%;
        margin: 0;
        padding: 0;
        border: unset;
      }

      &:focus {
        outline: none;
      }
    }

    select {
      border-radius: unset;
      border: unset;
      height: rem(50px);
      padding: rem(0 0);
      font-size: rem(18px);
      font-weight: 700;
      appearance: unset;

      &:focus {
        outline: none;
        border: none;
        box-shadow: unset;
      }
    }

    .nice-dates {
      @include mobile {
        width: 100%;
        overflow: hidden;
      }
      .date-range {
        @include mobile {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }
      .nice-dates-popover {
        @include mobile {
          width: 92%;
          margin: 0;
        }
      }
    }
  }
}
