@import "./../../assets/sass/main";

.PageBanner {
  width: 100%;
  height: 60vh;
  position: relative;

  @include mobile {
    display: none;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .container {
      height: 100%;
    }

    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    h1 {
      display: block;
      font-size: rem(30px);
      font-weight: 700;
      color: theme-color(secondary);
    }

    p {
      display: block;
      font-size: rem(13px);
      color: theme-color(secondary);
    }
  }
}
