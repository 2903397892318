@import "./../../assets/sass/main";

.TermAndConditions {
  label {
    font-size: 12px;
    margin-left: 0;
    color: lighten($black, 46.5%) !important;
    margin-bottom: 0 !important;
    @include mobile {
      margin-left: 0;
    }

    a {
      color: $black;
      text-decoration: underline;
      margin-left: 4px;
    }

    input {
      margin-top: 3px;
    }
  }
}
