@import "../../assets/sass/main";

.Logo {
  display: block;
  width: rem(185px);

  @include mobile {
    margin-left: rem(15px);
  }

  svg {
    display: block;
    width: 100%;
  }
}
