@import "./../../assets/sass/main";

.Home {
  width: 100%;
  height: 100vh;
  display: flex;

  @include mobile {
    height: auto;
    flex-direction: column;
  }

  &__left-side {
    width: 40%;
    position: relative;

    @include mobile {
      width: 100%;
      height: auto;
    }

    &-image {
      display: block;
      width: 100%;
      height: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &__right-side {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85vh;

    @include mobile {
      width: 100%;
      height: auto;
      margin-top: rem(-200px);
      z-index: 1;
    }

    h1 {
      display: block;
      width: 100%;
      text-align: center;
      font-size: rem(120px);
      color: rgba(0, 0, 0, 0.05);
      margin-bottom: rem(-40px);

      @include mobile {
        display: none;
      }
    }
  }
}
