@import "./assets/sass/main";

.App {
  width: 100%;

  &__main {
    min-height: 100vh;
  }

  &__loading {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    .Logo {
      width: rem(140px);
    }
  }
}
