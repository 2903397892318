@import "./../../assets/sass/main";

.CarItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: rem(20px);
  background-color: white;

  &__image {
    display: block;
    width: 100%;
    height: rem(210px);
    flex: 0 0 auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__name {
    display: block;
    font-size: rem(20px);
    color: theme-color(secondary);
    font-weight: 700;

    span {
      font-weight: 300;
      color: theme-color(primary);
    }
  }

  &__price {
    display: block;
    margin: rem(10px 0);
    font-size: rem(14px);
    color: theme-color(secondary);

    strong {
      font-size: rem(18px);
      font-weight: bold;
    }
  }

  &__details {
    display: flex;
    align-items: center;

    span {
      display: block;
      margin-right: rem(5px);
      background-color: darken(white, 10%);
      padding: rem(0 10px);
      line-height: rem(25px);
      font-size: rem(13px);

      &:last-child {
        margin-right: unset;
      }
    }
  }

  &__button {
    display: block;
    margin: rem(30px 0 0);
    line-height: rem(40px);
    padding: rem(0 20px);
    font-size: rem(14px);
    font-weight: 500;
    border: none;
    background-color: theme-color(secondary);
    color: white;
    transition: $transition;

    &:hover {
      background-color: theme-color(primary);
      color: white;
    }

    &:focus {
      outline: none;
    }
  }
}
