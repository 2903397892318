@import "./../../assets/sass/main";

.Register {
  width: 100%;
}

.register-wrapper {
  padding: rem(0 60px);
}

.RegisterForm__btn {
  height: rem(50px);
  color: white;
  background-color: theme-color(primary);
  border: none;
  padding: rem(0 30px);
  margin-top: rem(30px);

  &:focus {
    outline: none;
  }
}
