@import "./../../assets/sass/main";

.Login {
  width: 100%;

  &__form--btn {
    width: 100%;
    background-color: theme-color(primary);
    border: none;
    height: rem(50px);
    color: white;

    &:focus {
      outline: none;
    }
  }
}
