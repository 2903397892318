@import "./../../assets/sass/main";

.MainNavigation {
  @include mobile {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;
  }

  li {
    display: block;
    margin: rem(0 20px);
  }

  a {
    display: block;
    font-size: rem(13px);
    color: theme-color(secondary);
    font-weight: 700;
    transition: $transition;

    &:hover {
      text-decoration: none;
      color: theme-color(primary);
    }
  }

  &__auth {
    border: 1px lighten(theme-color(secondary), 50%) solid;
    padding: rem(0 20px);
    border-radius: rem(20px);
    line-height: rem(36px);
    display: flex !important;
    align-items: center;
    transition: $transition;

    svg {
      display: block;
      width: rem(15px);
      margin-right: rem(5px);
      transition: $transition;
    }

    &:hover {
      background-color: theme-color(primary);
      border: 1px theme-color(primary) solid;

      svg {
        stroke: white;
      }

      a {
        color: white;
      }
    }
  }
}
