@import "../../assets/sass/main";

.ReservationConfirm {
  margin-top: rem(200px);
  width: 100%;
  text-align: center;

  a {
    display: block;
    margin-top: rem(10px);
  }
}
